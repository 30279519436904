import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { debounce, isEmpty } from "lodash";
import { setActiveTab, setAttentionedMessage, createThread } from "../../../actions/ThreadAction";
import config from "../../../config";
import { parseAction } from "../../../actions/REST";
import Spinner from "../../Common/Spinner";
import DOMPurify from "dompurify";
import "./index.css";
import { useEffect } from "react";

const NavbarSearch = ({ history }) => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMessages, setIsMessagesLoading] = useState(false);
  const [isFocus, setFocus] = useState(false);
  const [results, setResults] = useState([]);
  const [resultsMessages, setResultsMessages] = useState([]);
  const [noResult, setNoResult] = useState(false);

  const checkFocus = () => {
    if (searchTerm !== "") {
      setFocus(true);
    }
  };

  useEffect(() => {
    if (
      (results.groups && results.groups.length == 0) &&
      (results.contacts && results.contacts.length == 0) &&
      (results.patients && results.patients.length == 0) &&
      (resultsMessages.messages && resultsMessages.messages.length == 0)
    ) {
      setNoResult(true);
    } else {
      setNoResult(false);
    }
    
  }, [results, resultsMessages])

  const searchGlobal = async (query) => {
    const url = config.BASE_URL + "/globalSearch";
    const params = { search: query };

    const res = await parseAction("post", url, params);
    const { result } = res;

    setResults((prevState) => ({
      ...prevState,
      ...result,
    }));
    setIsLoading(false);
  };

  const searchGlobalMessage = async (query) => {
    const url = config.BASE_URL + "/globalSearch/messages";
    const params = { search: query };

    const res = await parseAction("post", url, params);
    const { result } = res;

    setResultsMessages((prevState) => ({
      ...prevState,
      messages: result.messages,
    }));

    setIsMessagesLoading(false);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      searchGlobal(query);
      searchGlobalMessage(query);
    }, 500),
    []
  );

  const handleChange = (event) => {
    const value = event.target.value;
    if (value !== "") {
      setIsLoading(true);
      setIsMessagesLoading(true);
      setFocus(true);
      setSearchTerm(value);
      debouncedSearch(value);
      setNoResult(false);
    } else {
      setSearchTerm("");
      setResults([]);
      setResultsMessages([]);
      setFocus(false);
      setNoResult(false);
    }
  };

  const openThread = (thread, type, data) => {
    let id;
    switch (type) {
      case "messages":
        dispatch(setAttentionedMessage(data));
        dispatch(setActiveTab("message"));
        id = thread;
        break;
      case "contacts":
        dispatch(setActiveTab("contact"));
        id = thread;
        break;
      case "groups":
        dispatch(setActiveTab("group"));
        id = thread;
        break;
      case "patients":
        dispatch(setActiveTab("chart"));
        id = thread;
        break;
    }
    history.push({
      pathname: "/u/" + id,
    });
    if (type === "contacts") {
      dispatch(createThread(data, "contact"));
    }
  };

  const resultsStyle = isLoading && isLoadingMessages
    ? { height: "100px" }
    : { maxHeight: "600px" };

  return (
    <div className="d-flex justify-content-end" style={{ marginTop: "5px" }}>
      <div className="Tab-Search-Container">
        <i className="fas fa-search"></i>
        <input
          onChange={(e) => handleChange(e)}
          id="search"
          type="search"
          autocomplete="off"
          list="search"
          placeholder="Search Hubchart"
          onFocus={() => checkFocus()}
          onBlur={() => setFocus(false)}
        />
      </div>
      <div
        className={`Tab-Search-Results ${isFocus ? "" : "invisible"}`}
        style={{ ...resultsStyle }}
      >
        {isLoading && isLoadingMessages ? (
          <div style={{ height: "70px", marginTop: "25px" }} align="center">
            <Spinner top="50" />
          </div>
        ) : (
          <>
            {(noResult && resultsMessages.messages.length === 0) ? (
              <div className="d-flex" style={{ padding: "15px 45px" }}>
                <i className="fas fa-search fa-2x m-1" ></i>
                <p style={{ paddingTop: "7px" }}> We couldn't find anything matching your search.</p>
              </div>
            ) : (
              ""
            )}
            <ItemLength type={"Contacts"} items={results.contacts} />
            {results.contacts &&
              results.contacts.map((item) => (
                <Item
                  type={"contacts"}
                  item={item}
                  openThread={openThread}
                  key={item.objectId}
                />
              ))}

            <ItemLength type={"Patient Hub"} items={results.patients} />
            {results.patients &&
              results.patients.map((item) => (
                <Item
                  type={"patients"}
                  item={item}
                  openThread={openThread}
                  key={item.objectId}
                />
              ))}

            <ItemLength type={"Groups"} items={results.groups} />
            {results.groups &&
              results.groups.map((item) => (
                <Item
                  type={"groups"}
                  item={item}
                  openThread={openThread}
                  key={item.objectId}
                />
              ))}

            <ItemLength type={"Messages"} items={resultsMessages.messages} />
            {resultsMessages.messages &&
              resultsMessages.messages.map((item) => (
                <Item
                  type={"messages"}
                  item={item}
                  openThread={openThread}
                  key={item.objectId}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
};

const Item = ({ type, item = {}, openThread }) => {
  let content = {};

  switch (type) {
    case "contacts":
      content.main = 
        <div 
          className="content-main text-primary"
          style={{ marginTop: "10px" }}
          >
            {item.contact.displayName}
          </div>;
      if (item.contact.picture) {
        content.url = item.contact.picture.url;
      } else {
        content.url = require("../../../assets/images/default.png");
      }
      content.id = item.objectId;
      break;

    case "groups":
      content.main = <span className="content-main text-primary">{item.name.toUpperCase()}</span>;
      content.sub = <div>{item.owner.displayName}</div>;
      content.url = require("../../../assets/images/group-default.png");
      content.id = item.objectId;
      break;

    case "messages":
      const cleanHTML = DOMPurify.sanitize(item.text);
      const tempDiv = document.createElement("span");
      tempDiv.innerHTML = cleanHTML;
      const cleanText = tempDiv.innerText;
      const truncatedText = cleanText.length > 30 ? cleanText.slice(0, 20) + "..." : cleanText;

      if (item.circle && item.circle.groupType === "regular") {
        content.main = <span className="content-main">{item.circle.name.toUpperCase()}</span>;
        content.sub = (
          <div>
            {item.user.displayName}: <span className="text-primary">{truncatedText}</span>
          </div>
        );
        if (item.user.picture) {
          content.url = item.user.picture.url;
        } else {
          content.url = require("../../../assets/images/group-default.png");
        }
        content.id = item.threadId;
      } else if (item.circle && item.circle.groupType === "patient") {
        content.main = <span className="content-main">{item.circle.name.toUpperCase()}</span>;
        content.sub = (
          <div>
            {item.user.displayName}: <span className="text-primary">{truncatedText}</span>
          </div>
        );
        if (item.user.picture) {
          content.url = item.user.picture.url;
        } else {
          content.url = require("../../../assets/images/chart_gray.png");
        }
        content.id = item.threadId;
      } else if (item.user) {
        content.main = <span className="content-main">{item.user.displayName.toUpperCase()}</span>;
        content.sub = (
          <div>
            {item.user.displayName}: <span className="text-primary">{truncatedText}</span>
          </div>
        );
        if (item.user.picture) {
          content.url = item.user.picture.url;
        } else {
          content.url = require("../../../assets/images/default.png");
        }
        content.id = item.threadId;
      }
      break;

    case "patients":
      content.main = <span className="content-main text-primary pl-1">{item.name.toUpperCase()}</span>;
      content.sub = 
        <div className="d-flex flex-row">
          <div class="p-1"><i className="fas fa-user hc-primary-text"></i></div>
          <div class="p-1">{item.owner.displayName}</div>
          <div class="p-1"><i className="fal fa-calendar hc-primary-text"></i></div>
          <div class="p-1">{item.dob ? item.dob : "DOB: PENDING"}</div>
        </div>;

      if (item.image) {
        content.url = item.image.url;
      } else {
        content.url = require("../../../assets/images/chart_gray.png");
        content.img_style = {
          height: "25px",
          width: "20px",
          border: "0px",
          borderRadius: "0px",
          margin: "5.5px 9px",
        };
      }
      content.id = item.objectId;
      break;
  }

  return (
    <a
      className="dropdown-item d-flex flex-row item-result-search"
      href="#"
      style={{ border: "1px solid rgb(229, 229, 229)" }}
      onMouseDown={() => openThread(content.id, type, item)}
    >
      <div 
        className="m-1" 
        style={{ 
          border: "1px solid rgb(229, 229, 229)",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
        }}>
        <img src={content.url} style={content.img_style}/>
      </div>
      <div style={{marginLeft: "10px"}}>
        {content.main}
        {content.sub}
      </div>
    </a>
  );
};

const ItemLength = ({ type, items }) => {
  let length = items ? items.length : null;
  if (length > 0)
    return (
      <a role="option" className="dropdown-item disabled" style={{ paddingLeft: "10px" }}>
        {type}: {length}
      </a>
    );
  else {
    return "";
  }
};

export default NavbarSearch;
